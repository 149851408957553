<template>

 <div class="col-12"> 
 <h2>デバイス一覧</h2>
      <div class="table-responsive">
        <table class="table table-striped table-sm">
          <thead>
            <tr>
              <th>デバイス名</th>
              <th>アクセスキー</th>
              <th>状態</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="(device, key) in devices" :key="key">
                <td>
                 <span >{{ device.DeviceID }}</span >
                </td>
                <td> 
                <span v-on:dblclick="onAccessKeyDblClick(device.SharedAccessKey)" >
                <input :type="inputType" id="password" class="input" style="cursor :pointer;" v-model="device.SharedAccessKey" disabled>
                </span>
                    <span style="cursor :pointer;" class="input-icon">
                      <font-awesome-icon :icon="iconType" @click="onAccessKeyClick" />
                    </span>
                </td>
            
                <td> <span :class='(device.State === "DeviceConnected")?"text-success":"text-danger"  ' > {{ device.State }} </span></td>
            </tr>
          </tbody>
        </table>
      </div>
</div>
</template>

<script>

import { faEye , faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { defineComponent } from "vue";

import axios from 'axios';

export default defineComponent({
  name: "Devices",
 // components:  {  } ,
  data : function() {return { 
  isSharedKeyChecked : false ,
  devices: [] 
  } },
  computed: {
    inputType: function () {
      return this.isSharedKeyChecked  ? "text" : "password";
    },
    iconType: function () {
      return this.isSharedKeyChecked  ? faEye:faEyeSlash ;//"eye-slash" : "eye";
    }
  },
  mounted : function(){
    
       axios.get(process.env.VUE_APP_API_URL +"GetUserDevices"+ process.env.VUE_APP_API_CODE
        ,
        { withCredentials: true }
        )
       .then( function(json) {
         
           if( json.data){
                this.devices  = json.data.devices;
                return true;
            }
            return false; 
      }.bind(this));
    

   },
  methods: {
        onAccessKeyClick: function() {
            this.isSharedKeyChecked = !this.isSharedKeyChecked;
        },
        onAccessKeyDblClick: function (key){
           
            if (window.clipboardData) {
                window.clipboardData.setData('key', key);
               this.$emit('show-flash',{"message":"クリップボードにコピーしました。","type": "primary"});
           
            } else if (navigator.clipboard) {
                this.$emit('show-flash',{"message":key,"type": "primary"});
                navigator.clipboard.writeText(key)
                this.$emit('show-flash',{"message":"クリップボードにコピーしました。","type": "primary"});
            }
        }
    }
});

</script>
.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}